import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getAllInquiryData = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}api/inquiry`, config);
  return response;
};

const getAllInquiry = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/inquiry?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getOneInquiry = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}api/inquiry/${id}`, config);
  return response;
};

const updateInquiry = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const res = await axios.put(`${BACKEND_URL}api/inquiry/${id}`, config);
  return res;
};
const deleteInquiry = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const res = await axios.put(`${BACKEND_URL}api/inquiry/${id}`, config);
  return res;
};

export default {
  getAllInquiry,
  getOneInquiry,
  updateInquiry,
  deleteInquiry,
  getAllInquiryData,
};
