import React, { useEffect, useState } from "react";

import { instanceToken } from "../../../utils/constant";
import { useNavigate, Link } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
} from "@mui/material";
import userManualService from "../../../services/userManual";
export default function UserManual() {
  const navigate = useNavigate();

  const [faqs, setFaqs] = useState(null);
  useEffect(() => {
    const fetchEmergencies = async () => {
      try {
        const res = await userManualService.getAllUserManual(
          instanceToken.token
        );
        parse(res.data);
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    };
    fetchEmergencies();
  }, []);

  const parse = (data) => {
    setFaqs(data.data);
  };

  if (!faqs) {
    return <em>Loading</em>;
  }

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>User Manual</span>
        </Breadcrumbs>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          sx={{ my: 3 }}
          onClick={() => navigate("/user-manual/create")}
        >
          Create
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple-table">
          <TableHead>
            <TableRow className="thead">
              <TableCell>ID</TableCell>
              <TableCell>Question En</TableCell>
              <TableCell>Question MM</TableCell>
              <TableCell>Number Of Answer</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>

                <TableCell>{row.question_en}</TableCell>
                <TableCell>{row.question_mm}</TableCell>
                <TableCell>{row.answers.length}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    component={Link}
                    color="secondary"
                    to={`/user-manual/${row.id}`}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
