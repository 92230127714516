import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getAllUserManual = async (token, limit, offset) => {
  console.log("service", limit, offset);
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}api/user-manual`, config);
  return response;
};
const getUserManual = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/user-manual/${id}`,
    config
  );
  return response;
};
const deleteUserManual = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}api/user-manual/${id}`,
    config
  );
  return response;
};
const createUserManual = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}api/user-manual`,
    data,
    config
  );
  return response;
};
const updateUserManual = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.patch(
    `${BACKEND_URL}api/user-manual/${id}`,
    data,
    config
  );
  return response;
};
export default {
  getAllUserManual,
  getUserManual,
  deleteUserManual,
  createUserManual,
  updateUserManual,
};
